:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 15vh;
    /* Slick */
    --slick-slider-height: 92vh;
    /* Colors */
    --text-color: #565656;
    --main-color: #ababab;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: inherit;
    --header-bg-color: #cdcdcd;
    --footer-bg-color: #cdcdcd;
    --footer-text-color: inherit;
    --menu-bg-color: inherit;
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: #565656;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Montserrat, sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Montserrat.css";

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
    background: #f6f6f6;
}

@media (min-width: var(--breakpoint-s)) {
body.homepage.fixed {
border:none;

}

.homepage header {
background: rgba(255,255,255,0.4);

text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}
    
    .homepage nav {
background: none;
}
    
}

header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
    z-index: 10;
}



footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
}

.logo a {
   padding: .5rem;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}
        
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1rem;
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--menu-bg-color);
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
    text-transform: uppercase;
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
}

/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}

.slick-initialized .slick-slide {
    background-size: cover;
    background-position: bottom center;
    display: flex;
    height: 100%;
    min-height: var(--slick-slider-height);
}

.slick-title {
    max-width: var(--container-max-width);
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}

.slick-title h2, .slick-title h1  {
  font-size: calc(1.5 * var(--scale-factor) * var(--scale-factor)rem);
}
.slick-title p {
  font-size: calc(1 * var(--scale-factor)rem);
}

/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */;
}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    background: var(--main-color);
    color: var(--header-text-color);
    padding: 1rem;
}

.boltform input, .boltform textarea {
    width: 100%;
}

.boltform button {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
    border: none;
    margin: 0;
}

.boltform button:hover {
    background: color(var(--menu-text-bg-hover-color) lightness(60%));
    color: var(--menu-text-hover-color);
}

.boltform .boltforms-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 35vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1, .slick-title h2, .slick-title h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2, .slick-title p {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    

    
    
}

@media (max-width: var(--breakpoint-xs)) {
    h1, .slick-title h2, .slick-title h1  {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

     h2, .slick-title p {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}
